.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.homepage {
  position: relative;
}

.header {
  display: flex;
  align-items: center;
  padding: 10px 0;
  justify-content: space-between;
  width: 50%;
  margin: 0 auto;
  position: relative;
}

.ad-list {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 50%;
  margin: 0 auto;
  padding: 25px 0;
}

.ad {
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.active {
  border-radius: 8px;
  border: 0.5px solid #C6C6C6;
  background: #FFF;
  box-shadow: 0px 0px 5px 0px rgba(87, 87, 87, 0.25);
}

.channel-info {
  background-color: black;
  outline: 1px solid #2d2d2d;
  display: flex;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
}

.channel-image {
  background-color: #1d1d1d;
  outline: 1px solid #2d2d2d;
  padding: 0 10px;
  border-radius: 10px;
}

.remote-image {
  padding: 0 5px;
}

.ad-section {
  height: calc(100vh - 200px);
  padding: 20px 0;
}

.ad-container {
  width: 35%;
  margin: 0 auto;
  height: 100%;
  position: relative;
}

.ad-image {
  width: 100%;
  height: 100%;
}

.menu-section {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
  z-index: 3;
}

.channels-list-card {
  background-color: black;
  outline: 1px solid #2d2d2d;
  border-radius: 8px;
  width: 25%;
  z-index: 10;
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 20px;
}

.channel-heading {
  font-size: 14px;
  line-height: 18px;
  color: #b8b8b8;
  margin-bottom: 0;
  margin-left: 5px;
}

.channel-list {
  list-style-type: none;
  padding-left: 0;
}

.channel {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: transparent;
  padding-left: 10px;
  cursor: pointer;
  border-top: 1px solid #2d2d2d;
}

.channel:hover {
  background: linear-gradient(107deg, rgba(72, 72, 72, 0.20) -12.72%, rgba(139, 139, 139, 0.03) 27.11%, rgba(151, 151, 151, 0.00) 81.23%, rgba(96, 96, 96, 0.20) 131.58%), #1D1D1D;
}

.active-channel {
  background: linear-gradient(107deg, rgba(72, 72, 72, 0.20) -12.72%, rgba(139, 139, 139, 0.03) 27.11%, rgba(151, 151, 151, 0.00) 81.23%, rgba(96, 96, 96, 0.20) 131.58%), #1D1D1D;
}

.active-channel .channel-name {
  font-weight: 500;
}

.channel-name {
  font-size: 14px;
  line-height: 18px;
  color: #fff;
  margin-bottom: 0;
  padding: 5px;
}

.close-menu {
  padding: 10px;
  background: #242424;
  border-radius: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  cursor: pointer;
}

.banner-skeleton {
  width: 35%;
  margin: 0 auto;
  height: 100%;
  display: block;
}

.form-container {
  width: 30%;
  background-color: white;
  border-radius: 15px;
  padding: 15px;
}

.form-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.form-header .heading {
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  color: #454545;
  margin-bottom: 0;
}

.name-input,
.email-input,
.phone-input {
  margin-top: 15px;
}


.form-input,
.form-control {
  width: 100%;
  border: 0.5px solid #bababa;
  border-radius: 15px;
  padding: 5px 10px;
}

.form-input::placeholder {
  color: black;
}

.form-input:focus-visible {
  outline: none;
}

.submit-btn {
  margin-top: 15px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 20px;
  width: 100%;
  text-align: center;
  padding: 10px 0;
}

.iti--separate-dial-code,
#phone {
  width: 100%;
}

.iti__selected-flag {
  background-color: transparent !important;
}

#phone {
  border-radius: 15px;
  padding: 5px 10px;
  border: 0.5px solid #bababa;
}

#phone:focus-visible {
  outline: none;
}

.iti__arrow {
  content: url('/public/assets/images/country-code-arrow.svg');
  width: 20px !important;
  height: 20px !important;
  border: none !important;
  margin-left: 0 !important;
}

.iti__arrow--up {
  transform: rotate(180deg);
}

.ad-container button {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%, 0%);
  padding: 10px 0;
  text-align: center;
  width: 45%;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.explore-btn-arrow {
  margin-left: 5px;
  width: 30px;
  height: 20px;
}

.white-btn {
  background-color: white;
  color: black;
}

.black-btn {
  background-color: black;
  color: white;
}

.tool-tip {
  position: absolute;
  background-color: black;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 999;
  top: 20px;
  right: 120px;
}

.tool-tip.visible {
  visibility: visible;
  opacity: 1;
}

.tool-tip.hidden {
  visibility: hidden;
  opacity: 0;
}

.content {
  flex: 1;
  font-size: 12px;
  line-height: 14px;
}

.arrow {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid black;
  margin-left: 10px;
  position: absolute;
  transform: rotate(180deg);
  right: -7px;
  top: 50%;
  transform: translate(0, -50%) rotate(180deg);
}

.no-scroll {
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  .header {
    width: 100%;
    position: absolute;
    background: linear-gradient(90deg, rgba(77, 77, 77, 0.1554) 38.43%, rgba(77, 77, 77, 0) 100%);
    box-shadow: 0px 2px 5px 0px rgba(20, 20, 20, 0.25);
  }

  .container {
    padding: 0;
    position: relative;
  }

  .header {
    padding: 10px;
    z-index: 1
  }

  .ad-list {
    width: 75%;
    justify-content: space-between;
    padding: 10px 0;
  }

  .ad-section {
    padding: 0;
    display: flex;
    align-items: flex-end;
    height: calc(100svh - 80px);
  }

  .ad-container {
    width: 100%;
  }

  .channels-list-card {
    width: 70%;
  }

  .ad-image {
    object-fit: cover;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom: 1px solid #bababa;
  }

  .banner-skeleton {
    width: 90%;
    height: 88%;
  }

  .menu-section {
    align-items: flex-end;
    justify-content: flex-start;
    height: 100%;
  }

  .channels-list-card {
    margin: 10px;
  }

  .form-section {
    align-items: center;
    justify-content: flex-end;
  }

  .form-container {
    width: 90%;
    margin-bottom: 40px;
  }

  .tool-tip{
    right: 130px;
  }

  .ad-container button{
    width: 60%;
  }
}

@media screen and (min-width: 767px) and (max-width: 1024px) {
  .header {
    padding-top: 20px;
  }

  .ad-list {
    width: 40%;
  }

  .ad-container,
  .banner-skeleton {
    width: 70%;
  }

  .channels-list-card {
    width: 30%;
  }

  .ad-section {
    height: calc(100vh - 200px);
  }

  .tool-tip{
    top: 30px;
  }

  .ad-container button{
    bottom: 90px;
  }
}